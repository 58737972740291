<template>
	<div class="table">
		<el-table
			border
			ref="multipleTable"
			:data="tableData"
			:header-cell-style="{ 'text-align': 'center' }"
			:cell-style="{ 'text-align': 'center' }"
			tooltip-effect="dark"
			style="width: 100%"
			height="60vh"
			@selection-change="handleSelectionChange"
		>
			<el-table-column type="selection" width="55"> </el-table-column>

			<el-table-column prop="ticket_id" label="订单ID" width="80px">
			</el-table-column>
			<el-table-column prop="ticket_name" label="票务名称"> </el-table-column>
			<el-table-column prop="ticket_activityid" label="活动ID">
			</el-table-column>
			<el-table-column label="类型">
				<template slot-scope="scope">
					<span v-if="scope.row.ticket_type == 1">折扣</span>
					<span v-if="scope.row.ticket_type == 2">全价</span>
					<span v-if="scope.row.ticket_type == 0">免费</span>
				</template>
			</el-table-column>

			<el-table-column prop="ticket_create_time" label="支付时间">
			</el-table-column>
			<el-table-column prop="ticket_update_time" label="更新时间">
			</el-table-column>
			<el-table-column prop="ticket_userid" label="用户ID" width="80px">
			</el-table-column>
			<el-table-column prop="ticket_reason" label="退费原因"> </el-table-column>
			<el-table-column prop="ticket_email" label="联系邮箱" width="100px">
			</el-table-column>
			<!-- 订单状态:1-已完成下单;2-申请退费；3-退费完成；4-支付中 -->
			<el-table-column label="支付状态">
				<template slot-scope="scope">
					<span v-if="scope.row.ticket_status == 1">已支付</span>
					<span v-if="scope.row.ticket_status == 2">申请退款</span>
					<span v-if="scope.row.ticket_status == 3">退费完成</span>
					<span v-if="scope.row.ticket_status == 4">支付中</span>
					<span v-if="scope.row.ticket_status == 5">取消支付</span>
				</template>
			</el-table-column>
			<el-table-column fixed="right" label="操作" width="190">
				<template slot-scope="scope">
					<el-select
						v-model="scope.row.ticket_status"
						placeholder=""
						@change="handleStatusChange(scope.row, scope.row.ticket_status)"
					>
						<el-option label="已支付" :key="1" :value="1"> </el-option>
						<el-option label="申请退费" :key="2" :value="2"> </el-option>
						<el-option label="退费完成" :key="3" :value="3"> </el-option>
						<el-option label="支付中" :key="4" :value="4"> </el-option>
						<el-option label="取消支付" :key="5" :value="5"> </el-option>
					</el-select>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
import { destroyactivity, orderUpdate } from "@/api/article";

export default {
	props: ["tableData"],
	components: {},
	name: "",
	data() {
		return {
			value: true,
			title: "",
		};
	},
	methods: {
		handleStatusChange(selectedOption, status) {
			console.log("对应:", selectedOption);
			console.log("对应:", status);
			// 在这里执行你想要的操作

			orderUpdate({
				id: selectedOption.ticket_id,
				status: status,
			}).then((res) => {
				if (res.code == 200) {
					this.$message({
						type: "success",
						message: "更新成功!",
					});
					this.$parent.$parent.getDataList();
				}
			});
		},
		toEdit(row) {},
		toDelete(row) {
			this.$confirm("此操作将删除该条活动, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					destroyactivity({ id: row.id }).then((res) => {
						if (res.code == 200) {
							this.$message({
								type: "success",
								message: "删除成功!",
							});
							this.$parent.$parent.getDataList();
						}
					});
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除",
					});
				});
		},
		toggleSelection(rows) {
			if (rows) {
				rows.forEach((row) => {
					this.$refs.multipleTable.toggleRowSelection(row);
				});
			} else {
				this.$refs.multipleTable.clearSelection();
			}
		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
	},
};
</script>

<style scoped></style>
